import React, { FC, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import IconCustom from 'common/IconCustom/IconCustom';
import GatsbyImage from 'common/GatsbyImage';

import { IPropsVideoPlayer } from './models';
import './VideoPlayer.scss';

const VideoPlayer: FC<IPropsVideoPlayer> = ({
  videoURL,
  thumbnail,
  thumbnailAltText,
  videoPlayButtonAriaLabel,
  isThumbnailLazyLoading,
  className,
}) => {
  const ref = useRef<null | HTMLVideoElement>(null);
  const [showStartButton, setShowStartButton] = useState<boolean>(true);

  const togglePlay = () => {
    setShowStartButton(false);
    ref?.current && ref.current.play();
  };

  const trackScrolling = () => {
    if (ref.current) {
      const { height, y } = ref.current.getBoundingClientRect();

      if (Math.abs(y) > height) {
        ref.current?.pause();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  });

  return (
    <div
      className={classNames('video-player__wrapper', {
        'video-player__wrapper--border': showStartButton,
      })}
    >
      <video ref={ref} controls className={classNames('video-player__video', className)}>
        <source src={videoURL} type="video/mp4" />
        <track default kind="captions" />
      </video>
      {showStartButton ? (
        <>
          {thumbnail ? (
            <GatsbyImage
              isLazyLoading={isThumbnailLazyLoading}
              alt={thumbnailAltText}
              image={thumbnail}
              className={classNames('video-player__thumbnail', className)}
              data-test="video-thumbnail"
            />
          ) : null}
          <button
            aria-label={videoPlayButtonAriaLabel}
            className="video-player__play-button icon-social-youtube"
            onClick={togglePlay}
            type="button"
            data-test="video-play-button"
          >
            <IconCustom icon="play" className="video-player__play-icon" />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
