import React, { FC, useMemo } from 'react';

import BodyRenderer, { IComponentsStructures } from 'common/BodyRenderer';
import { DangerouslySetInnerHtml } from 'layout';
import ArticleImage from 'components/ArticleImage';

import './AccordionItemBody.scss';
import { IAccordionItemBodyCompositions } from '@shared/types';

const AccordionItemBody: FC<IAccordionItemBodyCompositions> = ({ accordionItemBody }) => {
  const elements: IComponentsStructures = useMemo(
    () => ({
      'Accordion Item Text': ({ properties: { accordionItemText } }, keyId) => (
        <DangerouslySetInnerHtml key={keyId} className="text-block" html={accordionItemText} />
      ),
      'Accordion Item Image': ({
        properties: { accordionItemImage, accordionItemImageAltText },
      }) => (
        <div className="accordion-item__image">
          <ArticleImage
            dataTest="ArticleBodyImage"
            image={accordionItemImage}
            alt={accordionItemImageAltText}
            objectFit="cover"
          />
        </div>
      ),
    }),
    []
  );

  return (
    <div className="accordion-item" data-test="AccordionItemBody">
      <BodyRenderer bodyData={accordionItemBody} bodyStructure={elements} />
    </div>
  );
};

export default AccordionItemBody;
