import React, { FC, useMemo } from 'react';
import YouTube from 'react-youtube';

import { getId } from 'utils/stringUtils/stringUtils';

import { VideoYouTubeProps } from './models';

const VideoYouTube: FC<VideoYouTubeProps> = ({ videoURL, className }) => {
  const videoId = useMemo(() => getId(videoURL), [videoURL]);

  return <YouTube videoId={videoId} className={className} containerClassName="youtube-wrapper" />;
};

export default VideoYouTube;
