import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { textWithOutSpaces } from 'utils/stringUtils/stringUtils';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import './ArticleTableOfContentsItem.scss';

const ArticleTableOfContentsItem = ({ navigationName }) => {
  const settings = {
    to: navigationName,
    duration: 500,
    spy: true,
    smooth: true,
    offset: -120,
  };

  return (
    <li className="article-table-of-contents-item" data-test="ArticleTableOfContentsItem">
      <ScrollLink
        {...settings}
        activeClass="active"
        className="article-table-of-contents-item__link"
        href={`#${textWithOutSpaces(navigationName)}`}
      >
        <span>
          <DangerouslySetInnerHtml html={navigationName} element="span" />
        </span>
      </ScrollLink>
    </li>
  );
};

export default ArticleTableOfContentsItem;
