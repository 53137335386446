import React, { FC, ReactElement, useMemo } from 'react';

import AccordionWithDatesItem from 'components/AccordionWithDatesItem';

import { isEmptyArray } from 'utils/array';

import './AccordionWithDates.scss';
import { IPropsAccordionWithDatesSection } from './models';

const AccordionWithDates: FC<IPropsAccordionWithDatesSection> = ({
  accordionSectionBody,
  accordionSectionTitle,
  accordionWithDateSectionBackground,
}): ReactElement | null => {
  if (isEmptyArray(accordionSectionBody)) return null;

  const style = useMemo(
    () =>
      accordionWithDateSectionBackground?.value
        ? { backgroundColor: accordionWithDateSectionBackground.value }
        : {},
    [accordionWithDateSectionBackground]
  );

  return (
    <div className="accordion" data-test="AccordionWithDates">
      <div className="accordion__background" style={style} />
      {accordionSectionTitle ? (
        <div className="accordion__title">{accordionSectionTitle}</div>
      ) : null}
      <div>
        {accordionSectionBody.map(
          (
            {
              properties: {
                accordionItemHeader,
                accordionItemAriaLabel,
                accordionItemYear,
                accordionItemBody,
              },
            },
            i
          ) => (
            <AccordionWithDatesItem
              key={accordionItemHeader}
              accordionItemHeader={accordionItemHeader}
              accordionItemAriaLabel={accordionItemAriaLabel}
              i={i}
              accordionItemYear={accordionItemYear}
              accordionItemBody={accordionItemBody}
            />
          )
        )}
      </div>
    </div>
  );
};

export default AccordionWithDates;
