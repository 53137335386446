import React, { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import AccordionItemBody from 'components/AccordionItemBody';

import './AccordionWithDatesItem.scss';
import { IPropsAccordionWithDataItem } from './models';

const AccordionWithDatesItem: FC<IPropsAccordionWithDataItem> = ({
  i,
  accordionItemHeader,
  accordionItemAriaLabel,
  accordionItemYear,
  accordionItemBody,
}) => {
  const year = useMemo(() => moment(accordionItemYear).format('YYYY'), []);
  const [isOpenAccording, setOpenAccording] = useState<boolean>(i === 0);
  const setSelectedItemCb = useCallback(() => setOpenAccording((prevState) => !prevState), []);

  return (
    <div className="accordion-item" data-test="AccordionWithDatesItem">
      <div className="accordion-item__row up-row">
        <div className="accordion-item__date">{year}</div>
        <div className="accordion-item__content">
          <button
            className="accordion-item__button accordion-item__content__title"
            type="button"
            aria-label={accordionItemAriaLabel || accordionItemHeader}
            onClick={setSelectedItemCb}
          >
            {accordionItemHeader}
          </button>
        </div>
        <div className="accordion-item__control">
          <button
            className="accordion-item__button"
            type="button"
            aria-label={accordionItemAriaLabel || accordionItemHeader}
            onClick={setSelectedItemCb}
          >
            <span
              className={classNames('icon accordion-item__icon icon-arrow', {
                'accordion-item__icon--open': isOpenAccording,
              })}
            />
          </button>
        </div>
      </div>
      <div className="accordion-item__row down-row">
        <div className="accordion-item__date">
          <div className="line" />
        </div>
        <div className={classNames('accordion-item__content', { active: isOpenAccording })}>
          <AccordionItemBody accordionItemBody={accordionItemBody} />
        </div>
        <div className="accordion-item__control" />
      </div>
    </div>
  );
};

export default AccordionWithDatesItem;
