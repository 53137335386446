import React, { FC } from 'react';
import classNames from 'classnames';

import VideoPlayer from 'components/Video/VideoPlayer';
import VideoYouTube from 'components/Video/VideoYouTube';

import './Video.scss';
import { IPropsVideo } from './models';

const Video: FC<IPropsVideo> = ({
  videoURL,
  videoCMS,
  thumbnail,
  thumbnailAltText,
  videoPlayButtonAriaLabel,
  className = '',
  videoClassName = '',
  isThumbnailLazyLoading,
}) => (
  <div className={classNames('video-frame', className)} data-test="Video">
    {videoURL ? (
      <VideoYouTube
        videoURL={videoURL}
        className={classNames('video-frame__player', videoClassName)}
      />
    ) : (
      <VideoPlayer
        videoURL={videoCMS.url}
        className={classNames('video-frame__player', videoClassName)}
        thumbnail={thumbnail}
        thumbnailAltText={thumbnailAltText}
        isThumbnailLazyLoading={isThumbnailLazyLoading}
        videoPlayButtonAriaLabel={videoPlayButtonAriaLabel}
      />
    )}
  </div>
);
export default Video;
