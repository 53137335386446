import React from 'react';

import ArticleTableOfContentsItem from 'components/ArticleTableOfContentsItem';

import './ArticleTableOfContents.scss';
import './ShadowArticleTableOfContents.scss';

const ArticleTableOfContents = ({ articleTableOfContents, articleTableOfContentsTitle }) =>
  articleTableOfContents.length >= 1 ? (
    <div className="article-table-of-contents" data-test="ArticleTableOfContents">
      <div className="article-table-of-contents__title">
        {articleTableOfContentsTitle || 'In this article'}
      </div>
      <ul className="article-table-of-contents__list">
        {articleTableOfContents.map((item) => (
          <ArticleTableOfContentsItem
            key={item.properties.navigationName}
            navigationName={item.properties.navigationName}
          />
        ))}
      </ul>
    </div>
  ) : null;

export default ArticleTableOfContents;
