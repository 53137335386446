import React, { FC, useCallback, useMemo, useState } from 'react';

import { UmbracoCustomTabs } from '@shared/types';

import CustomTabs, { ICustomTabsItemData } from 'common/CustomTabs';
import { DangerouslySetInnerHtml } from 'layout';

import './ArticleCustomTabs.scss';

const ArticleCustomTabs: FC<UmbracoCustomTabs.IData> = ({
  customTabs,
  isCustomTabsTableMode,
  customTabsDescription,
  navigationName,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onSelectCallback = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  const tabsItems: ICustomTabsItemData[] = useMemo(
    () =>
      customTabs.map((item) => ({
        title: item.properties.customTabsTitle,
        ariaLabel: item.properties?.customTabsAriaLabel || item.properties.customTabsTitle,
        content: item.properties?.customTabsText ? (
          <DangerouslySetInnerHtml html={item.properties.customTabsText} />
        ) : (
          ''
        ),
      })),
    [customTabs]
  );

  return (
    <div className="article-custom-tabs__wrapper" data-test="ArticleCustomTabs">
      <CustomTabs
        items={tabsItems}
        selectedIndex={selectedIndex}
        onSelectCallback={onSelectCallback}
        isCustomTabsTableMode={isCustomTabsTableMode === '1'}
        customTabsDescription={customTabsDescription}
        title={navigationName}
      />
    </div>
  );
};

export default ArticleCustomTabs;
