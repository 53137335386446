import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { IPropsAccordionSectionItem } from './models';

const AccordionSectionItem: FC<IPropsAccordionSectionItem> = ({
  i,
  accordionItemHeader,
  accordionItemText,
  accordionItemAriaLabel,
}) => {
  const [isOpenAccording, setOpenAccording] = useState<boolean>(i === 0);
  const setSelectedItemCb = useCallback(() => setOpenAccording((prevState) => !prevState), []);

  return (
    <div
      key={accordionItemHeader}
      className="accordion-section__item"
      data-test="AccordionSectionItem"
    >
      <button
        data-test="AccordionSectionButton"
        className="accordion-section__item__button"
        type="button"
        onClick={setSelectedItemCb}
        aria-label={accordionItemAriaLabel || accordionItemHeader}
      >
        <span className="accordion-section__item__header">{accordionItemHeader}</span>
        <span
          className={classNames('icon accordion-section__item__icon icon-arrow', {
            'accordion-section__item__icon--open': isOpenAccording,
          })}
        />
      </button>
      <DangerouslySetInnerHtml
        className={classNames('text-block', { active: isOpenAccording })}
        html={accordionItemText}
      />
    </div>
  );
};

export default AccordionSectionItem;
