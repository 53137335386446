import React, { FC, useMemo, useEffect, useState, createRef } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import BodyRenderer, { IComponentsStructures } from 'common/BodyRenderer';
import ArticleSection from 'components/ArticleSection';
import ArticleCustomTabs from 'components/ArticleCustomTabs';
import ArticleTableOfContents from 'components/ArticleTableOfContents';
import ArticleImage from 'components/ArticleImage';
import Video from 'components/Video/Video';
import AccordionSection from 'components/AccordionSection';
import AccordionWithDates from 'components/AccordionWithDates';
import TargetingSection from 'components/TargetingSection';
import FeaturedProducts from 'components/FeaturedProducts';
import ProductsFinder from 'components/ProductsFinder';
import ScrollElement from 'components/ScrollElement';
import ArticleReferences from 'components/ArticleReferences';
import ArticleDate from 'components/ArticleDate';
import FeaturedItems from 'components/FeaturedItems';

import { TABLET_BREAKPOINT, ELEMENTS } from 'utils/constants';

import { IPropsArticleBody } from './models';
import './ArticleBody.scss';

// activeColor is a part of the future ProductFamily functionality
const activeColor = '#FDBD21';

const ArticleBody: FC<IPropsArticleBody> = ({
  articleBody,
  defaultCompositions,
  lang,
  featuredProductsByLinkInBody,
  featuredProductsByTagInBody,
  articleTableOfContentsTitle,
  selectedProductsIds,
  articleSettings,
  createDate,
  updateDate,
}) => {
  const articleTableOfContents = useMemo(
    () => articleBody.filter((item) => item.properties.navigationName),
    [articleBody]
  );

  const elements: IComponentsStructures = useMemo(
    () => ({
      Targeting: (targetingSection, keyId) => (
        <TargetingSection
          key={keyId}
          targetingSection={[
            {
              properties: { ...targetingSection.properties },
            },
          ]}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          productQuizData={defaultCompositions.productQuiz}
          lang={lang}
        />
      ),
      'Start Quiz Button Body Wrapper': ({ properties: { startQuiz } }, keyId) => (
        <ProductsFinder
          key={keyId}
          startQuiz={startQuiz}
          productQuizData={defaultCompositions.productQuiz}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          lang={lang}
        />
      ),
      'Featured Products with URLs': (featuredProductsList, keyId) => (
        <ScrollElement name={featuredProductsList.properties.navigationName}>
          <FeaturedProducts
            key={keyId}
            featuredProducts={[
              {
                properties: { ...featuredProductsList.properties },
              },
            ]}
            featuredProductsByLink={featuredProductsByLinkInBody?.nodes || []}
            featuredProductsByTag={featuredProductsByTagInBody?.nodes || []}
            productsOrder={selectedProductsIds || []}
            dir={defaultCompositions.siteSettings.dir[0]}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            activeColor={activeColor}
            maxSlides={3}
          />
        </ScrollElement>
      ),
      'Article section': ({ properties }, keyId) => (
        <ScrollElement name={properties.navigationName}>
          <ArticleSection key={keyId} properties={properties} />
        </ScrollElement>
      ),
      'Article Body Image': ({
        properties: { articleBodyImage, articleBodyImageAltText, navigationName, autoHeight },
      }) => (
        <ScrollElement name={navigationName}>
          <ArticleImage
            dataTest="ArticleBodyImage"
            image={articleBodyImage}
            alt={articleBodyImageAltText}
            objectFit="cover"
            isLazyLoading
            autoHeight={autoHeight}
          />
        </ScrollElement>
      ),
      Video: (
        {
          properties: {
            videoLink,
            videoCMS,
            videoCMSThumbnail,
            videoCMSThumbnailAltText,
            videoPlayButtonAriaLabel,
            navigationName,
          },
        },
        keyId
      ) => (
        <ScrollElement name={navigationName}>
          <Video
            key={keyId}
            videoURL={videoLink}
            videoCMS={videoCMS}
            thumbnail={videoCMSThumbnail}
            thumbnailAltText={videoCMSThumbnailAltText}
            videoPlayButtonAriaLabel={videoPlayButtonAriaLabel}
            isThumbnailLazyLoading
          />
        </ScrollElement>
      ),
      'Video Youtube': (
        {
          properties: {
            videoLink,
            videoCMS,
            videoCMSThumbnail,
            videoCMSThumbnailAltText,
            videoPlayButtonAriaLabel,
            navigationName,
          },
        },
        keyId
      ) => (
        <ScrollElement name={navigationName}>
          <Video
            key={keyId}
            videoURL={videoLink}
            videoCMS={videoCMS}
            thumbnail={videoCMSThumbnail}
            thumbnailAltText={videoCMSThumbnailAltText}
            videoPlayButtonAriaLabel={videoPlayButtonAriaLabel}
            isThumbnailLazyLoading
          />
        </ScrollElement>
      ),
      'Accordion Section': (
        { properties: { accordionSectionTitle, accordionSectionBody, navigationName } },
        keyId
      ) => (
        <ScrollElement name={navigationName}>
          <AccordionSection
            key={keyId}
            accordionSectionTitle={accordionSectionTitle}
            accordionSectionBody={accordionSectionBody}
          />
        </ScrollElement>
      ),
      'Accordion with dates': (
        {
          properties: {
            accordionSectionBody,
            accordionSectionTitle,
            accordionWithDateSectionBackground,
            navigationName,
          },
        },
        keyId
      ) => (
        <ScrollElement name={navigationName}>
          <AccordionWithDates
            key={keyId}
            accordionSectionBody={accordionSectionBody}
            accordionSectionTitle={accordionSectionTitle}
            accordionWithDateSectionBackground={accordionWithDateSectionBackground}
          />
        </ScrollElement>
      ),
      'Custom Tabs': (
        {
          properties: { customTabs, navigationName, isCustomTabsTableMode, customTabsDescription },
        },
        keyId
      ) => (
        <ScrollElement name={navigationName}>
          <ArticleCustomTabs
            key={keyId}
            customTabs={customTabs}
            isCustomTabsTableMode={isCustomTabsTableMode}
            customTabsDescription={customTabsDescription}
            navigationName={navigationName}
          />
        </ScrollElement>
      ),
      References: ({ properties, properties: { navigationName } }, keyId) => (
        <ScrollElement name={navigationName}>
          <ArticleReferences key={keyId} articleReferenceSection={{ properties }} />
        </ScrollElement>
      ),
      'Article Date': ({ properties: { articleDateShow } }, keyId) => (
        <ArticleDate
          key={keyId}
          articleSettings={articleSettings}
          createDate={createDate}
          updateDate={updateDate}
          lang={lang}
          articleDateShow={articleDateShow}
        />
      ),
      'Featured Items': ({ properties }, keyId) => (
        <FeaturedItems
          key={keyId}
          image={properties.image}
          imageAlt={properties.imageAlt}
          items={properties.items}
        />
      ),
    }),
    [articleBody]
  );

  const [isSticky, setSticky] = useState(false);
  const ref = createRef<HTMLDivElement>();
  let headerHeight = 115;
  const handleScroll = () => {
    if (ref?.current?.children?.[0] && window.innerWidth > TABLET_BREAKPOINT) {
      const curElement = ref.current.children[0] as HTMLElement;
      const parentValues = ref.current.getBoundingClientRect();
      const elementValues = ref.current.children[0].getBoundingClientRect();
      curElement.style.width = `${parentValues.width}px`;

      const startSticky = parentValues.top <= headerHeight;
      const endSticky =
        parentValues.top - elementValues.height > -parentValues.height + headerHeight;

      if (startSticky && !endSticky) {
        if (isSticky) {
          curElement.style.position = 'absolute';
          curElement.style.bottom = '10px';
          curElement.style.top = 'auto';
          setSticky(false);
        }
      }

      if (startSticky && endSticky) {
        if (!isSticky) {
          curElement.style.position = 'fixed';
          curElement.style.bottom = '';
          curElement.style.top = `${headerHeight}px`;
          setSticky(true);
        }
      }

      if (!startSticky && endSticky) {
        if (isSticky) {
          curElement.style.position = '';
          curElement.style.bottom = '';
          curElement.style.top = '';
          setSticky(false);
        }
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth < TABLET_BREAKPOINT) {
      if (ref && ref.current) {
        const curElement = ref.current.children[0] as HTMLElement;
        if (!curElement) return;

        curElement.style.width = '';
        curElement.style.position = '';
        curElement.style.bottom = '';
        curElement.style.top = '';
      }
    }
  };

  useEffect(() => {
    const header = document.getElementById(ELEMENTS.HEADER_ID);
    headerHeight = header ? header.getBoundingClientRect().height + 10 : 115;
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
      window.removeEventListener('resize', () => handleResize);
    };
  }, [handleScroll]);

  return (
    <div
      className={classNames('article-body', {
        'article-body--with-table': articleTableOfContents.length >= 1,
      })}
    >
      {articleTableOfContentsTitle ? (
        <div
          className={classNames('sticky-wrapper', {
            sticky: isSticky,
          })}
          ref={ref}
        >
          <ArticleTableOfContents
            articleTableOfContents={articleTableOfContents}
            articleTableOfContentsTitle={articleTableOfContentsTitle}
          />
        </div>
      ) : null}
      <div
        className={classNames('article-body__content', {
          'article-body__content--with-table': articleTableOfContents.length >= 1,
        })}
      >
        <BodyRenderer bodyData={articleBody} bodyStructure={elements} />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentArticleBody on TArticleBodyStructure {
    structure
    properties {
      navigationName
      articleSectionBackgroundColour {
        label
        value
      }
      articleSectionBody
      articleDateShow
      articleSectionTitle
      articleSectionTitleRte
      articleBodyImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxHeight: 560, fit: COVER, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      articleBodyImageAltText
      autoHeight
      videoLink
      videoCMS {
        fallbackUrl
        url
      }
      videoCMSThumbnail {
        ...FragmentGatsbyImage
      }
      videoCMSThumbnailAltText
      videoPlayButtonAriaLabel
      accordionSectionTitle
      accordionSectionBody {
        structure
        properties {
          accordionItemText
          accordionItemAriaLabel
          accordionItemHeader
          accordionItemYear
          accordionItemBody {
            properties {
              accordionItemText
              accordionItemImage {
                ...FragmentGatsbyImage
              }
              accordionItemImageAltText
            }
            structure
          }
        }
      }
      accordionWithDateSectionBackground {
        value
        label
      }
      customTabs {
        properties {
          customTabsAriaLabel
          customTabsText
          customTabsTitle
        }
      }
      isCustomTabsTableMode
      customTabsDescription
      targetingText
      startQuizButtonTargeting {
        properties {
          ariaLabel
          boldTitle
        }
      }
      targetingButton {
        ...FragmentButton
      }
      startQuiz {
        ...FragmentStartQuizSection
      }
      sectionTitle
      ctaButton {
        properties {
          ariaLabel
          label
          link {
            url
            name
          }
        }
      }
      selectedProductsMode
      content {
        properties {
          item
        }
        structure
      }
      title
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 300, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      imageAlt
      items {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          link {
            name
            url
            target
          }
        }
      }
    }
  }
`;

export default ArticleBody;
