import React, { FC, useMemo } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import { articleFormatDate } from 'utils/helpers';

import { IArticleDateProps } from './model';
import './ArticleDate.scss';

const ArticleDate: FC<IArticleDateProps> = ({ articleSettings, createDate, updateDate, lang }) => {
  const { articleUpdateDate, articleCreateDate } = useMemo(
    () => ({
      articleUpdateDate: updateDate ? articleFormatDate(updateDate, lang) : '',
      articleCreateDate: createDate ? articleFormatDate(createDate, lang) : '',
    }),
    [updateDate, createDate]
  );

  return (
    <div className="article-date">
      <div className="article-date__wrapper">
        {articleSettings?.createDateText ? (
          <DangerouslySetInnerHtml
            className="article-date__text"
            html={articleSettings?.createDateText}
          />
        ) : null}
        <DangerouslySetInnerHtml className="article-date__date" html={articleCreateDate} />
      </div>
      <div className="article-date__wrapper">
        {articleSettings?.updateDateText ? (
          <DangerouslySetInnerHtml
            className="article-date__text"
            html={articleSettings?.updateDateText}
          />
        ) : null}
        <DangerouslySetInnerHtml className="article-date__date" html={articleUpdateDate} />
      </div>
    </div>
  );
};

export default ArticleDate;
