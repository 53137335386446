import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import { IPropsArticleReferences } from './models';
import './ArticleReferences.scss';

const ArticleReferences: FC<IPropsArticleReferences> = ({
  articleReferenceSection: {
    properties: { title, content },
  },
}) => (
  <div className="article-references" data-test="ArticleReferences">
    <div className="article-references__title">{title}</div>
    <ol className="article-references__list">
      {content.map((referenceItem) => (
        <li
          key={referenceItem.properties.item}
          className="article-references__list__list-item"
          data-test="ArticleReferenceItem"
        >
          <DangerouslySetInnerHtml
            element="div"
            className="text-block"
            html={referenceItem.properties.item}
          />
        </li>
      ))}
    </ol>
  </div>
);

export const query = graphql`
  fragment FragmentArticleReference on TReferences {
    structure
    properties {
      content {
        properties {
          item
        }
        structure
      }
      title
    }
  }
`;

export default ArticleReferences;
