import React, { FC } from 'react';

import AccordionSectionItem from 'components/AccordionSection/AccordionSectionItem';

import { IPropsAccordionSection } from './models';
import './AccordionSection.scss';

const AccordionSection: FC<IPropsAccordionSection> = ({
  accordionSectionTitle,
  accordionSectionBody,
}) => (
  <div className="accordion-section">
    <div className="accordion-section__wrapper">
      <div className="accordion-section__title">{accordionSectionTitle}</div>
      {accordionSectionBody.map(
        ({ properties: { accordionItemHeader, accordionItemText, accordionItemAriaLabel } }, i) => (
          <AccordionSectionItem
            key={accordionItemHeader}
            accordionItemHeader={accordionItemHeader}
            accordionItemAriaLabel={accordionItemAriaLabel}
            accordionItemText={accordionItemText}
            i={i}
          />
        )
      )}
    </div>
  </div>
);

export default AccordionSection;
