import React, { FC, useMemo } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { IPropsArticleSection } from './models';
import './ArticleSection.scss';
import './ShadowArticleSection.scss';

const ArticleSection: FC<IPropsArticleSection> = ({
  properties: {
    articleSectionTitle,
    articleSectionTitleRte,
    articleSectionBody,
    articleSectionBackgroundColour,
  },
}) => {
  const style = useMemo(
    () =>
      articleSectionBackgroundColour?.value
        ? { backgroundColor: `#${articleSectionBackgroundColour.value}` }
        : {},
    [articleSectionBackgroundColour]
  );

  return (
    <div className="article-section" data-test="ArticleSection">
      <div className="article-section__background" style={style} />
      <div className="article-section__wrapper">
        {articleSectionTitle && !articleSectionTitleRte ? (
          <h2 className="article-section__title">{articleSectionTitle}</h2>
        ) : null}
        {articleSectionTitleRte ? (
          <DangerouslySetInnerHtml
            className="article-section__title"
            html={articleSectionTitleRte}
          />
        ) : null}
        <DangerouslySetInnerHtml className="text-block" html={articleSectionBody} />
      </div>
    </div>
  );
};

export default ArticleSection;
